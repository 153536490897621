import type { Ref } from 'vue'
import { computed } from 'vue'
import { addSeconds, intervalToDuration, isAfter } from 'date-fns'
import { useCurrentDate } from '~/composables/use-current-date'

export function useContractExpiration(expirationDate: Ref<Date | null>): {
    expirationPast: Ref<boolean>
    expirationRelativeString: Ref<string>
} {
    const reactiveDate = useCurrentDate()
    const expirationPast = computed(() =>
        expirationDate.value === null
            ? false
            : isAfter(reactiveDate.value, addSeconds(expirationDate.value, 2))
    )
    const expirationRelativeString = computed(() => {
        if (expirationDate.value === null) {
            return ''
        }

        if (expirationPast.value) {
            return 'Item Expired.'
        }

        const duration = intervalToDuration({
            start: reactiveDate.value,
            end: expirationDate.value
        })

        if (
            (duration.hours !== undefined && duration.hours > 0) ||
            (duration.days !== undefined && duration.days > 0) ||
            (duration.weeks !== undefined && duration.weeks > 0) ||
            (duration.months !== undefined && duration.months > 0) ||
            (duration.years !== undefined && duration.years > 0)
        ) {
            return ''
        }

        const minutes = duration.minutes ?? 0
        const seconds = duration.seconds ?? 0

        return (
            `Booking reservation will expire in <span class="tabular-nums" style="font-family: 'Helvetica Neue'"">${minutes}:` +
            (seconds < 10 ? `0${seconds}` : `${seconds}`) +
            '</span>'
        )
    })

    return { expirationPast, expirationRelativeString }
}
