import type { Ref } from 'vue'
import { onMounted, onUnmounted, readonly, ref } from 'vue'

export function useCurrentDate(): Ref<Date> {
    const reactiveDate = ref(new Date())
    function updateDate() {
        reactiveDate.value = new Date()
    }
    let updateDateTimer: ReturnType<typeof setInterval> | null = null
    onMounted(() => {
        updateDateTimer = setInterval(updateDate, 1000)
    })
    onUnmounted(() => {
        if (updateDateTimer !== null) {
            clearInterval(updateDateTimer)
            updateDateTimer = null
        }
    })

    return readonly(reactiveDate)
}
